// generic

<template>
  	<v-container fluid pa-0>
		<v-card tile>
			<v-toolbar dense flat color="blue-grey darken-3">
				<v-btn ripple icon @click="$router.back()"><v-icon color="black">mdi-arrow-left-bold-box</v-icon></v-btn>
				<v-text-field flat solo dense hide-details background-color="blue-grey darken-3" prefix="Kunde:" readonly
					:value="item.name"
				></v-text-field>  
				<v-spacer />
				<v-btn disabled plain small>ID: {{ 31 }}</v-btn>
				<v-btn ripple icon><v-icon color="yellow darken-3">mdi-star</v-icon></v-btn>
			</v-toolbar>
			<v-tabs v-model="htab" background-color="blue-grey darken-3" color="grey" active-class="blue-grey darken-1 black--text"
				align-with-title
				slider-color="blue-grey darken-1"
				dark dense height="35px"
				next-icon="mdi-arrow-right-bold-box-outline"
				prev-icon="mdi-arrow-left-bold-box-outline"
				show-arrows show-arrows-on-hover center-active
			>
				<v-tab v-for="(item, hindex) in cfgRefTables" :key="hindex">
					{{ item.table_name }}
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="htab">
				<v-tab-item v-for="(item, hindex) in cfgRefTables" :key="hindex" class="secondary">
					<GenericItem v-if="genericItemModul(cfgRefTables[hindex])"
						:modulData="modulData(cfgRefTables[hindex].table_name)" />
					<GenericList v-if="genericListModul(cfgRefTables[hindex])"
						:modulData="modulData(cfgRefTables[hindex].table_name)" />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>
<script>
import GenericList from './GenericList'
import GenericItem from './GenericItem'
export default {
	name: 'generic',
	components: {
		GenericList, GenericItem
	},
	props: {
		routeData: {
			type: Object,
			required: true,
			default: {},
		},
	},
	data: () => ({
		search: '',
		cfgRefTables: [],
		htab: 0,
		item: {},
	}),
	computed: {
	},
	methods: {
		async getData() {
			this.loading = true
			const param = {
				tablename: this.routeData.meta.tablename,
				data: this.routeData.params,
			}
            try {
                await this.$store.dispatch('generic/getItem', param)
                const result = this.$store.getters['generic/getItem']
                this.item = Object.assign({}, result)
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		genericItemModul(cfgRefTable) {
			if(!this.item.id) return false
			if(cfgRefTable.table_name==cfgRefTable.reftable) return true
		},
		genericListModul(cfgRefTable) {
			if(!this.item.id) return false
			if(cfgRefTable.table_name!=cfgRefTable.reftable) return true
		},
		modulData(tablename) {
			const modulData = Object.assign({}, this.routeData)
			modulData.meta.item = this.item
			modulData.meta.subtable = tablename
			return modulData
		},
		onSearch(){
			
		},
		onSearchClear(){
			
		},
		getCfgs() {
			const param = {
				table_name: this.routeData.meta.tablename,
			}
			this.cfgRefTables = this.$store.getters['core/getCfgRefTables'](param)
			console.log('generic [cfgRefTables]:', this.cfgRefTables)
		},
	},
	beforeMount() {
		console.log('generic [routeData]:', this.routeData)
		this.getCfgs()
		this.getData()
	},
};
</script>
<style>
.truncate-text {
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-text-field__prefix {
    color: rgba(255, 255, 255, 0.3);
}
</style>