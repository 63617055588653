// generator

<template>
  	<v-container fluid pa-0 ma-0>
    <v-row>
    <v-col>
    <v-card width="1000" tile elevation="0">
      <v-toolbar dense color="blue-grey darken-3" elevation="1">
        <v-toolbar-title>{{ cfgTable.table_title }}</v-toolbar-title>
        <v-spacer />
		<v-text-field flat solo dense hide-details label="Suche" placeholder="Suche" prepend-inner-icon="mdi-magnify" background-color="blue-grey darken-2"
			 v-model="filter" clearable clear-icon="mdi-close-circle-outline" @click:clear="onSearchClear()" @keyup.enter="onSearch()"
		></v-text-field>
        <v-spacer />
        <v-btn color="blue-grey darken-4" @click.stop="onSearch()">Search</v-btn>
        <v-spacer />
        <v-btn color="blue-grey darken-4" @click.stop="onEditItem()">New</v-btn>
      </v-toolbar>
        <v-data-table
			:headers="cfgLists"
			:items="filteredItems"
			item-key="id"
			:page.sync="page"
      		:items-per-page="perPage"
      		must-sort
      		:sort-by.sync="sortBy"
      		:sort-desc.sync="sortDesc"
			:custom-sort="customSort"
			class="grey darken-3 table-cursor"
      		hide-default-footer
			:loading="loading"
			@click:row="onRowClick($event)"
		>
			<template v-for="cfgCol in cfgLists.filter((cfgCol) => cfgCol.format != 'defaultx')"
				v-slot:[`item.${cfgCol.value}`]="{ value }">
				<span class="truncate-text" :key="cfgCol.id" :style="styleItem(value, cfgCol.format)">{{ formatItem(value, cfgCol.format) }}</span>
			</template>
		    <template v-slot:[`item.id`]="{ item }">
		    	<v-chip color="blue-grey" dark x-small>{{ item.id }}</v-chip>
		    </template>
			<template v-slot:[`item.action`]="{ item }">
				<v-hover v-slot:default="{ hover }">
				<v-btn small fab color="grey darken-3" :class="{ 'success': hover }" @click.stop="onEditItem(item.id)" :loading="false" :disabled="false">
					<v-icon>create</v-icon></v-btn></v-hover>
				<v-hover v-slot:default="{ hover }">
				<v-btn small fab color="grey darken-3" :class="{ 'warning': hover }" @click.stop="onPrintItem(item.id)" :loading="false" :disabled="false">
					<v-icon small>delete</v-icon></v-btn></v-hover>
			</template>
      		<template slot="footer">
      			<div class="text-xs-center pt-2">
					<v-pagination color="blue-grey" v-model="page" :length="pages" :total-visible="7" @input="onPageChange"></v-pagination>
				</div>
      		</template>
    	</v-data-table>
        <v-overlay absolute :value="loading">
        	<!--<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>-->
        </v-overlay>
    </v-card>
	</v-col>
	</v-row>
    <GenericForm v-if="edit" :modulData="modulData" @cancelled="editCancelled()" @submitted="editSubmitted()" />
	<v-dialog v-model="showmessage">
		<v-toolbar dark color="warning" dense>
			<v-toolbar-title class="white--text">{{ message.title }}</v-toolbar-title>
		</v-toolbar>
		<v-card tile>
			<v-card-text color="red" v-html="message.msg"/>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="blue" text @click="onMessageClose()">Ok</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
    <v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
      <v-btn text dark @click="snack.active = false">Ok</v-btn>
    </v-snackbar>
	</v-container>
</template>

<script>
import mixins from './helpers'
import GenericForm from './GenericForm'
export default {
	name: 'GenericList',
	mixins: [mixins],
	components: {
		GenericForm,
	},
	props: {
		routeData: {
			type: Object,
			required: true,
			default: {},
		},
	},
	data () {
		return {
			loading: false,
			dialog: false,
			items: [],
			item: {},
			edit: false,
			page: 1,
			pages: 1,
			perPage: 10,
			sortBy: 'id',
			sortChangeTime: null,
			sortDesc: true,
			filter: '',
			message: {},
			showmessage: false,
			snack: { active: false, type: 'success', text: 'saved', },
			cfgTable: {},
			cfgLists: [],
		}
	},
	computed: {
		filteredItems: function () {
			let self = this
			if(!self.items.length) {return []}
			if(!self.searchQuery || self.searchQuery.length < 3) {
				return self.items
			} else {
				return self.items.filter(function (item) {
					let searchRegex = new RegExp(self.searchQuery, 'i')
					return searchRegex.test(item.name)
				})
			}
		},
		modulData() {
			if(!this.routeData) return null
			const modulData = Object.assign({}, this.routeData)
			modulData.meta.subtable = modulData.meta.tablename
			return modulData
		},
	},
	mounted() {
		this.getCfgs()
		this.getData()
	},
	methods: {
		customSort: function(items, index, isDesc) {
			return items;
		},
		async getData() {
            this.loading = true
			let param = {
				tablename: this.modulData.meta.tablename,
				page: this.page,
				sortBy: this.sortBy,
				sortDesc: (this.sortDesc ? 'desc' : 'asc'),
				filter: this.filter || '',
			}
            try {
                await this.$store.dispatch('generic/getItems', param)
                const result = this.$store.getters['generic/getItems']( param )
                this.items = result.data
				this.page = result.current_page
				this.pages = result.last_page
				this.perPage = result.per_page
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		async delItem(item) {
            this.loading = true
			let param = {
				tablename: this.modulData.meta.tablename,
				data: item,
			}
            try {
                await this.$store.dispatch('generic/delItem', param)
				console.log('deleted')
				this.getData()
            } catch (error) {
                console.error(error)
                this.message.title = error.name
                this.message.msg = '<code>' + JSON.stringify(error.message).substring(0, 200) + '</code>'
                this.showmessage = true
            } finally {
                this.loading = false
            }
		},
		getCfgs() {
			let param = {
				table_name: this.modulData.meta.tablename,
				reftable: this.modulData.meta.tablename,
			}
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgLists = this.$store.getters['core/getCfgLists'](param)
			this.cfgLists.push({ text: 'Action', value: 'action', align: 'center', sortable: false, width: '100px', class: 'blue-grey darken-4', })
			//console.log("cfgLists:", this.cfgLists)
		},
		onRowClick(e) {
			//console.log('clicked:', e)
			const item = e
			this.$router.push({ name: this.cfgTable.model_name, params: { id: item.id } })
		},
		onSearch () {
			if(this.filter && this.filter.length < 3) return
			this.page = 1
			this.getData()
		},
		onSearchClear () {
			this.filter = ''
			this.page = 1
			this.getData()
		},
		onPageChange () {
			this.getData()
		},
		onPrintItem (id) {
			const item = Object.assign({}, this.filteredItems.find(x => x.id === id))
			this.delItem(item)
		},
		onEditItem (id = null) {
			if(id && id >= 0) {
				const item = Object.assign({}, this.filteredItems.find(x => x.id === id))
				this.modulData.meta.model = item
			} else {
				const item = {}
				this.modulData.meta.model = item
			}
			this.edit = true
		},
		editCancelled () {
			this.edit = false
			this.snack = {active: true, type: 'warning', text: 'cancelled'}
		},
		editSubmitted() {
			this.getData()
			this.edit = false
			this.snack = {active: true, type: 'success', text: 'saved'}
		},
		onSortChange () {
			this.$nextTick(() => {
				this.getData()
			})
		},
		onMessageClose() {
			this.message = {}
			this.showmessage = false			
		},
	},
	watch: {
		sortBy () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
		sortDesc () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
	},
}
</script>

<style scoped>
.selected {
    background-color: red
}
.table-cursor >>> tbody tr:hover {
	cursor: pointer;
}
.truncate-textx {
	max-width: 1px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>